import { get, post } from "../../shared/lib/requestUtils"
import type MinimalEntry from "./interfaces/plannerPool/MinimalEntry"
import type Pool from "./interfaces/plannerPool/Pool"
import type PoolEntry from "../../commonInterfaces/PoolEntry"
import { plannerPoolEnabled } from "../../shared/lib/clientFeatureConfigurationUtils/plannerPoolEnabled"

export class PlannerPoolService {

  public async getEffectiveDepartmentId(): Promise<string | undefined> {
    try {
      const res = await get<{
        departmentId?: string
      }>(url('getEffectiveDepartmentId'), {})
      return res.departmentId
    } catch (e) {
      console.error("An error occurred getting department ID:", e)
      return undefined
    }
  }

  public async isAllowedToUsePlannerPool(): Promise<boolean> {
    try {
      const res = await get<{
        allowed?: boolean
      }>(url('isAllowedToUsePlannerPool'), {})
      const allowed = (res?.allowed ?? false) && (await plannerPoolEnabled() ?? false)
      return allowed
    } catch (e) {
      console.error("An error occurred getting permission:", e)
      return false
    }
  }

  public async isAllowedToUsePlannerPoolIndiscriminately(): Promise<boolean> {
    try {
      const res = await get<{
        allowed?: boolean
      }>(url('isAllowedToUsePlannerPoolIndiscriminately'), {})
      return res?.allowed ?? false
    } catch (e) {
      console.error("An error occurred getting permission:", e)
      return false
    }
  }

  async getPools(
    departmentIds: string[],
    firstDate: string,
    numberOfDays: number
  ): Promise<{ [departmentId: string]: Pool[] }> {
    return await this.getPools_(departmentIds, firstDate, numberOfDays)
  }

  async getPool(departmentId: string, date: string): Promise<Pool> {
    const { pool } = await get<{ pool: Pool }>(url('getPool'), { departmentId, date })
    return pool ?? { entries: [] }
  }

  async addEntry(entry: PoolEntry): Promise<boolean> {
    const { success } = await post<PostReturnType>(
      url('addEntry'),
      entry
    )
    return success
  }

  async changeEntry(entry: PoolEntry): Promise<boolean> {
    const { success } = await post<PostReturnType>(
      url('changeEntry'),
      entry
    )
    return success
  }

  async removeEntry(entry: MinimalEntry): Promise<boolean> {
    const { success } = await post<PostReturnType>(
      url('removeEntry'),
      entry
    )
    return success
  }

  /*
  async assignEntry(entry: PoolEntryAssigned): Promise<boolean> {
    const { success } = await post<PostReturnType>(
      url('assignEntry'),
      entry
    )
    return success
  }

  async returnEntry(entry: MinimalEntry): Promise<boolean> {
    const { success } = await post<PostReturnType>(
      url('returnEntry'),
      entry
    )
    return success
  }
  */

  async markEntryAsUnassigned(entry: MinimalEntry): Promise<boolean> {
    const { success } = await post<PostReturnType>(
      url('markEntryAsAvailable'),
      entry
    )
    return success
  }

  async markEntryAsAssigned(entry: MinimalEntry): Promise<boolean> {
    const { success } = await post<PostReturnType>(
      url('markEntryAsUnavailable'),
      entry
    )
    return success
  }

  private async getPools_(
    departmentIds: string[],
    startDate: string,
    numberOfDays: number
  ): Promise<{ [departmentId: string]: Pool[] }> {
    const { pools } = await get<{ pools: Record<string, Pool[]> }>(url('getPools'), {
      departmentIds: JSON.stringify(departmentIds), startDate, numberOfDays: `${numberOfDays}`
    })
    return pools
  }

}

function url(name: string): string {
  return `/rest/plannerpool/${name}`
}

interface PostReturnType {
  success: boolean
}

export function makePlannerPoolService(): PlannerPoolService {
  return new PlannerPoolService()
}

const plannerPoolService = makePlannerPoolService()

export default plannerPoolService
